import axios from 'axios';
import * as constants from '../constants';

export function fetchApplications() {
  return {
    type: constants.FETCH_APPLICATIONS,
    payload: {
      promise: axios.get(`${window.config.DAP_API_BASE_PATH}/api/applications`, {
        responseType: 'json'
      })
    }
  };
}
