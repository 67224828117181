import moment from 'moment';
import { fromJS } from 'immutable';

import * as constants from '../constants';
import logTypes from '../utils/logTypes';
import createReducer from '../utils/createReducer';

const initialState = {
  loading: false,
  error: null,
  userId: null,
  record: { },
  memberships: [],
  connection: {},
  devices: {
    loading: false,
    error: null,
    records: { }
  }
};

const userDevices = createReducer(fromJS(initialState.devices), {
  [constants.FETCH_USER_DEVICES_PENDING]: (state) =>
    state.merge({
      ...initialState.devices,
      loading: true
    }),
  [constants.FETCH_USER_DEVICES_REJECTED]: (state, action) =>
    state.merge({
      ...initialState.devices,
      error: action.errorData
    }),
  [constants.FETCH_USER_DEVICES_FULFILLED]: (state, action) => {
    const devices = action.payload.data.devices.reduce((map, device) => {
      map[device.device_name] = (map[device.device_name] || 0) + 1;
      return map;
    }, { });

    return state.merge({
      loading: false,
      records: fromJS(devices)
    });
  }
});

export const user = createReducer(fromJS(initialState), {
  [constants.FETCH_USER_PENDING]: (state, action) =>
    state.merge({
      error: null,
      loading: true,
      userId: action.meta.userId
    }),
  [constants.FETCH_USER_REJECTED]: (state, action) =>
    state.merge({
      loading: false,
      error: action.errorData
    }),
  [constants.FETCH_USER_FULFILLED]: (state, action) => {
    const { data } = action.payload;
    if (data.user.user_id !== state.get('userId')) {
      return state;
    }

    return state.merge({
      loading: false,
      record: fromJS(data.user),
      memberships: fromJS(data.memberships),
      connection: fromJS(data.connection)
    });
  },

  [constants.FETCH_USER_DEVICES_PENDING]: (state, action) =>
    state.merge({
      devices: userDevices(state.get('devices'), action)
    }),
  [constants.FETCH_USER_DEVICES_REJECTED]: (state, action) =>
    state.merge({
      devices: userDevices(state.get('devices'), action)
    }),
  [constants.FETCH_USER_DEVICES_FULFILLED]: (state, action) =>
    state.merge({
      devices: userDevices(state.get('devices'), action)
    })
});
